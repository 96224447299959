// ** Initial State
const initialState = {
    scheduledMessages: [],
    historicMessages: [],
    messages: [],
    total: 1,
    totalSelectedCustomers: 0,
    params: {}
}

const odm = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_DATA':
            return { ...state }
        case 'ODM_HISTORY':
            return { ...state, historicMessages: action.data }
        case 'ODM_SCHEDULE':
            return { ...state, scheduledMessages: action.data }
        case 'ODM_SELECTED_CUSTOMERS_COUNT':
            return { ...state, totalSelectedCustomers: action.data.total }
        default:
            return { ...state }
    }
}
export default odm
