// ** Initial State
const initialState = {
  adminDashboard: null
}

const adminDashboard = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DASHBOARD':
      return { ...state, adminDashboard: action.adminDashboard }
    default:
      return { ...state }
  }
}
export default adminDashboard
