// ** Initial State
const initialState = {
    storageLocations: null,
    storageSeasons: null,
    currentStorageLocation: null,
    storageZones: null,
    storageItems: null,
    totalStorageItems: 0,
    customerNames: [],
    params: {}
}

const storage = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_DATA':
            return { ...state }
        case 'GET_SEASONS':
            return { ...state, storageSeasons: action.data }
        case 'GET_LOCATIONS':
            return { ...state, storageLocations: action.data }
        case 'GET_CUSTOMER_NAMES':
            return { ...state, customerNames: action.data }
        case 'GET_STORAGE_ZONES':
            return { ...state, storageZones: action.data }
        case 'GET_STORAGE_ITEMS':
            return { ...state, storageItems: action.data.items, totalStorageItems: action.data.total }
        case 'GET_LOCATION':
            return { ...state, currentStorageLocation: action.data }
        case 'ODM_SCHEDULE':
            return { ...state, scheduledMessages: action.data }
        case 'ODM_SELECTED_CUSTOMERS_COUNT':
            return { ...state, totalSelectedCustomers: action.data.total }
        default:
            return { ...state }
    }
}
export default storage
