// ** Initial State
const initialState = {
    messages: null,
    nextPageUrl: null,
    prevPageUrl: null
}

const sms = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_SMS_DATA':
            return { ...state, messages: action.data.messages, nextPageUrl: action.data.nextPageUrl, prevPageUrl: action.data.prevPageUrl }
        default:
            return { ...state }
    }
}
export default sms
